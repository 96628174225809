import React from 'react';
import logo from './logo.svg';
import './App.css';

import Isle, { Archipelago } from './react-isle';

function App() {
    // const ref = React.createRef<HTMLDivElement>();

    const getRandomArbitrary = (range:Array<number>) => {
        return Math.random() * (range[1] - range[0]) + range[0];
    }

    const generateIsles = (count:number) => {
        const scaleRange = [0.5, 1.5];
        const rotRange = [-30, 30];
        const xRange = [0, 500];
        const yRange = [100, 500];

        const isles = [];

        for(let i=0; i<count; i++) {
            isles.push(
                <Isle params={{ 
                    minimizeDurationMs: 300,
                    scale: getRandomArbitrary(scaleRange), 
                    x: getRandomArbitrary(xRange), 
                    y: getRandomArbitrary(yRange), 
                    rotation: getRandomArbitrary(rotRange) }}>
                    <img src={logo} className="App-logo" alt="logo"
                        style={{backgroundColor: "#" + Math.floor(Math.random()*16777215).toString(16)}} />
                </Isle>);
        }

        return isles;
    }

    return (
        <div className="App">
            <Archipelago>
                {generateIsles(10)}
                     
                {/*<Isle className="my-isle" onMaximize={() => alert("Maxi")}
                    params={{ scale: 1, x: 20, y: 250, rotation: 30 }}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Isle>

                <Isle hideFrame params={{ scale: 0.5, x: 270, y: 30 }}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Isle>

                <Isle title=""
                    params={{ scale: 0.5, x: 220, y: 150 }}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Isle> */}
            </Archipelago>
    {/*         
            <Isle title="Errant Isle">
                <img src={logo} className="App-logo" alt="logo" />
            </Isle> */}
        </div>
    );
}

export default App;